import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { clear } from "console";
export interface initialState {
  category: any;
  orgMenuItem: any;
  categoryItemsIds: any;
  dataToEdit?: any;
}

const initialState: initialState = {
  category: [],
  orgMenuItem: [],
  categoryItemsIds: [],
  dataToEdit: {},
};

const UpSell = createSlice({
  name: "upsell",
  initialState,
  reducers: {
    insertPropsData: (
      state: initialState,
      action: PayloadAction<initialState>
    ) => {
      state.category = action.payload.category;
      state.categoryItemsIds = action.payload.categoryItemsIds;
      state.orgMenuItem = action.payload.orgMenuItem;
      state.dataToEdit = action.payload.dataToEdit || {};
    },
    deletePropsData: (state: initialState) => {
      state.category = [];
      state.categoryItemsIds = [];
      state.orgMenuItem = [];
      state.dataToEdit = {};
    },
  },
});

export const { insertPropsData, deletePropsData } = UpSell.actions;

export const getCategory = (state: RootState) => state.upSell.category;
export const getOrgMenuItem = (state: RootState) => state.upSell.orgMenuItem;
export const getCategoryItemsIds = (state: RootState) =>
  state.upSell.categoryItemsIds;
export const getDataToEdit = (state: RootState) => state.upSell.dataToEdit;

export default UpSell.reducer;
