import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { tableType } from "../../lib/types";

interface ValidateInfo {
  pin?: string[];
  staff_id?: string;
  staff_pin?: string[];
}
export interface StaffServiceType {
  staffService: StaffService | null;
  staffServiceCategories: StaffServiceCategory[];
  selectedItem: StaffServiceItem | null;
  selectedCategory: StaffServiceCategory | null;
  staffQrRooms: any[];
  mode: boolean;
  operationTasks: StaffOperationTasks[];
  isStaffQrPage: boolean;
  isAuthorized: boolean;
  staffData: { id: number; name: string } | null;
  requireAuthorized: boolean;
  validateInfo: ValidateInfo;
  locationId: number;
  tableId: number;
  locale: string;
}

const initialState: StaffServiceType = {
  staffService: null,
  staffServiceCategories: [],
  selectedItem: null,
  selectedCategory: null,
  staffQrRooms: [],
  mode: false,
  operationTasks: [],
  isStaffQrPage: false,
  isAuthorized: false,
  requireAuthorized: false,
  staffData: null,
  validateInfo: {
    pin: ["", "", "", ""],
    staff_pin: ["", "", "", "", "", ""],
    staff_id: "",
  },
  locationId: 0,
  tableId: 0,
  locale: "",
};

const staffService = createSlice({
  name: "staffservice",
  initialState,
  reducers: {
    insertStaffService: (state, action) => {
      state.staffService = action.payload;
    },
    insertStaffServiceCategories: (state, action) => {
      state.staffServiceCategories = action.payload;
    },
    updateStaffServiceCategories: (state, action) => {
      state.staffServiceCategories = state.staffServiceCategories.map(
        (category) => {
          if (category.id === action.payload.id) {
            return action.payload;
          } else {
            return category;
          }
        }
      );
    },
    insertItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    insertCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    insertStaffQrRooms: (state, action) => {
      state.staffQrRooms = action.payload;
    },
    updateMode: (state: any, action) => {
      state.mode = action.payload;
    },
    insertStaffOperationTasks: (state, action) => {
      state.operationTasks = action.payload;
    },
    insertIsStaffQrPage: (state, action) => {
      state.isStaffQrPage = action.payload;
    },
    insertIsAuthorized: (state, action) => {
      state.isAuthorized = action.payload;
    },
    insertStaffData: (state, action) => {
      state.staffData = action.payload;
    },
    insertRequiredAuthorized: (state, action) => {
      state.requireAuthorized = action.payload;
    },
    insertValidateInfo: (state, action) => {
      state.validateInfo = action.payload;
    },
    insertStaffLocale: (state, action) => {
      state.locale = action.payload;
    },
  },
});

export const {
  insertStaffService,
  insertStaffServiceCategories,
  updateStaffServiceCategories,
  insertItem,
  insertCategory,
  insertStaffQrRooms,
  updateMode,
  insertStaffOperationTasks,
  insertIsStaffQrPage,
  insertIsAuthorized,
  insertRequiredAuthorized,
  insertValidateInfo,
  insertStaffData,
  insertStaffLocale,
} = staffService.actions;

export const getStaffService = (state: RootState) =>
  state.staffService.staffService;

export const getStaffServiceCategories = (state: RootState) =>
  state.staffService.staffServiceCategories;

export const getSelectedItem = (state: RootState) =>
  state.staffService.selectedItem;

export const getSelectedCategory = (state: RootState) =>
  state.staffService.selectedCategory;

export const getStaffQrRooms = (state: RootState) =>
  state.staffService.staffQrRooms;

export const getMode = (state: any) => state.staffService.mode;

export const getStaffOperationTasks = (state: RootState) =>
  state.staffService.operationTasks;

export const getIsStaffQrPage = (state: RootState) =>
  state.staffService.isStaffQrPage;

export const getIsAuthorized = (state: RootState) =>
  state.staffService.isAuthorized;

export const getRequiredAuthorized = (state: RootState) =>
  state.staffService.requireAuthorized;

export const getValidateInfo = (state: RootState) =>
  state.staffService.validateInfo;

export const getStaffLocale = (state: RootState) => state.staffService.locale;

export const getStaffData = (state: RootState) => state.staffService.staffData;

export default staffService.reducer;
