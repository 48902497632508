import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface CustomServiceType {
  staffQrRooms: any[];
  mode: boolean;
  isCustomQrPage: boolean;
  locale: string;
}

const initialState: CustomServiceType = {
  staffQrRooms: [],
  mode: false,
  isCustomQrPage: false,
  locale: "",
};

const customService = createSlice({
  name: "customservice",
  initialState,
  reducers: {
    insertCustomQrRooms: (state, action) => {
      state.staffQrRooms = action.payload;
    },
    updateMode: (state: any, action) => {
      state.mode = action.payload;
    },
    insertIsCustomQrPage: (state, action) => {
      state.isCustomQrPage = action.payload;
    },
    insertStaffLocale: (state, action) => {
      state.locale = action.payload;
    },
  },
});

export const {
  insertCustomQrRooms,
  updateMode,
  insertIsCustomQrPage,
  insertStaffLocale,
} = customService.actions;

export const getCustomQrRooms = (state: RootState) =>
  state.customService.staffQrRooms;

export const getMode = (state: any) => state.customService.mode;

export const getIsCustomQrPage = (state: RootState) =>
  state.customService.isCustomQrPage;

export const getStaffLocale = (state: RootState) => state.staffService.locale;

export default customService.reducer;
