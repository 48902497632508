import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface MenuInitialStateType {
  categories: any[];
  miniBarCategories: any[];
  menus: [];
  miniMenus: any[];
  filterItems: [];
  company: {
    id: number | null;
    name: string | null;
    address: string | null;
    image_url: string | null;
    cover_photo: string | null;
    service_charge_rate: number;
    tax_rate: number;
    user_id: number | null;
    type: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  };
  locationPhoneNumber: string;
  rsCategories: any[];
  rsMenus: any[];
}

const initialState: MenuInitialStateType = {
  categories: [],
  miniBarCategories: [],
  miniMenus: [],
  menus: [],
  rsCategories: [],
  rsMenus: [],
  filterItems: [],
  company: {
    id: null,
    name: null,
    address: null,
    cover_photo: null,
    image_url: null,
    service_charge_rate: 0,
    tax_rate: 0,
    user_id: null,
    type: null,
    createdAt: null,
    updatedAt: null,
  },
  locationPhoneNumber: "",
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    insertCategories: (state, action) => {
      state.categories = action.payload;
    },
    insertMenus: (state, action) => {
      state.menus = action.payload;
    },
    insertTempMiniBarItems: (state, action) => {
      state.miniBarCategories = action.payload.categories;
      state.miniMenus = action.payload.menus;
    },
    insertTempRsItems: (state, action) => {
      state.rsCategories = action.payload.categories;
      state.rsMenus = action.payload.menus;
    },
    insertCompany: (state, action) => {
      state.company = action.payload;
    },
    insertLocationPhoneNumber: (state, action) => {
      state.locationPhoneNumber = action.payload;
    },
  },
});

export const {
  insertCategories,
  insertMenus,
  insertLocationPhoneNumber,
  insertCompany,
  insertTempMiniBarItems,
  insertTempRsItems,
} = menu.actions;

export const getMiniBarTempData = (state: RootState) => {
  return {
    categories: state.menu.miniBarCategories,
    menus: state.menu.miniMenus,
  };
};
export const getRsTempData = (state: RootState) => {
  return {
    categories: state.menu.rsCategories,
    menus: state.menu.rsMenus,
  };
};

export default menu.reducer;
