import { services as Service } from "@prisma/client";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateType {
  popUpOpen: boolean;
  orderServiceState: "" | "rs" | "minibar";
}

const initialState: initialStateType = {
  popUpOpen: true,
  orderServiceState: "",
};

const CfPopUpReducer = createSlice({
  name: "CfPopUpReducer",
  initialState,
  reducers: {
    insertCfPopUpStatus: (
      state: initialStateType,
      action: PayloadAction<boolean>
    ) => {
      state.popUpOpen = action.payload;
    },
    setOrderServiceState: (
      state: initialStateType,
      action: PayloadAction<"" | "rs" | "minibar">
    ) => {
      state.orderServiceState = action.payload;
    },
  },
});

export const { insertCfPopUpStatus, setOrderServiceState } =
  CfPopUpReducer.actions;

export const getPopUpStatus = (state: RootState) =>
  state.CfPopUpReducer.popUpOpen;

export const getOrderServiceState = (state: RootState) =>
  state.CfPopUpReducer.orderServiceState;

export default CfPopUpReducer.reducer;
