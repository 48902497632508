import moment from "moment";
import { serviceOrderType } from "../lib/types";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const isAvailableAtCurrentTime = (data: any, timezone: string) => {
  if (data === null || data === undefined) {
    return false;
  }
  if (data.enable_service === false) {
    return false;
  }
  if (data.is_timebased === false) {
    return true;
  }
  if (data.start_hour === 0) {
    return true;
  }
  const currentTime = dayjs().tz(timezone);

  // Calculate the start and end times in the app's timezone
  const startHour = dayjs()
    .tz(timezone)
    .startOf("day")
    .add(data.start_hour, "minutes");

  const endHour = dayjs()
    .tz(timezone)
    .startOf("day")
    .add(data.end_hour, "minutes");

  // Check if the current time falls between startHour and endHour
  const availableNow =
    currentTime.isAfter(startHour) && currentTime.isBefore(endHour);

  return availableNow;
};

export const isWithinSubscriptionPeriod = (data: {
  is_manage_by_stripe: boolean;
  start_date: string;
  end_date: string;
}) => {
  if (data?.is_manage_by_stripe) {
    return true;
  }

  // Parsing dates
  const startDate = moment(data.start_date, "YYYY-MM-DDTHH:mm:ss.SSSZ", true); // Parse with strict mode
  let endDate = moment(
    data.end_date,
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    true
  ).subtract(1, "days");
  const currentDate = moment(); // Current local date-time

  if (!startDate.isValid() || !endDate.isValid()) {
    console.error("Invalid start_date or end_date format");
    return false;
  }

  const isWithinPeriod = currentDate.isBetween(startDate, endDate, null, "[]");

  return isWithinPeriod;
};
